import React from 'react'
import './Pin.css'

export default function Pin ({ pin, setPin }) {
  const handlePinChange = ({ target }) => {setPin(target.value)}

  return (
    <div className="pin">
      <div className="header">Welcome back</div>
      <div className="description">Enter your PIN to log in</div>
      <div className="input-container">
        <input type="password" autoFocus={true} value={pin} onChange={handlePinChange}/>
      </div>
    </div>
  )
}
