import React, { useState } from 'react'
import './Login.css'
import { getAccessToken } from '../../api/auth'
import { useNavigate } from 'react-router-dom'
import PhoneNumber from '../../components/PhoneNumber/PhoneNumber'
import Pin from '../../components/Pin/Pin'

export default function Login ({ phoneNumber, setPhoneNumber, setAccessToken }) {
  const [screen, setScreen] = useState('phoneNumber')
  const [pin, setPin] = useState('')
  const navigate = useNavigate()

  const handleContinue = () => {setScreen('pin')}
  const handleSubmit = async () => {
    try {
      const accessToken = await getAccessToken(phoneNumber, pin)
      setAccessToken(accessToken)
      navigate('/users')
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="login-container">
      {screen === 'phoneNumber' ? (
        <>
          <PhoneNumber phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}/>
          <div className="button" onClick={handleContinue}>Continue</div>
        </>
      ) : (
        <>
          <Pin pin={pin} setPin={setPin}/>
          <div className="button" onClick={handleSubmit}>Submit</div>
        </>
      )}
    </div>
  )
}
