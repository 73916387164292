import React from 'react'
import './DeleteUser.css'

export default function DeleteUser ({ deleteAccount, deleteApplication }) {
  return (
    <div className="delete-user-container">
      <div className="description">Are you sure you want to delete the account or application?</div>
      <div className="buttons">
        <div className="button" onClick={deleteAccount}>Delete Account</div>
        <div className="button" onClick={deleteApplication}>Delete Application</div>
      </div>
    </div>
  )
}
