import React, { Fragment, useEffect, useState } from 'react'
import './Users.css'
import NavBar from '../../components/NavBar/NavBar'
import { deleteUserRecords, deleteUsers, getUsers } from '../../api/users'
import CheckIcon from '@mui/icons-material/Check'
import Modal from '../../components/Modal/Modal'
import DeleteUser from '../../components/DeleteUser/DeleteUser'

export default function Users ({ accessToken, phoneNumber }) {
  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  const [showModal, setShowModal] = useState(false)

  const getData = async () => {
    const data = await getUsers(accessToken)
    const sortedData = [
      data.find(d => d.phoneNumber === `+1${phoneNumber}`),
      ...(data.filter(d => d.phoneNumber !== `+1${phoneNumber}`))
    ]
    setUsers(sortedData)
  }

  useEffect(() => {
    getData()
  }, [])

  const formatPhoneNumber = (phoneNumber) => `(${phoneNumber.substring(2, 5)}) ${phoneNumber.substring(5, 8)}-${phoneNumber.substring(8, 12)}`
  const handleAllCheckBoxChange = ({ target }) => {
    if (target.checked) {
      setSelectedUsers(users.map(({ id }) => id))
    } else {
      setSelectedUsers([])
    }
  }
  const handleCheckBoxChange = ({ target }) => {
    if (target.checked) {
      setSelectedUsers([...selectedUsers, target.id])
    } else {
      setSelectedUsers(selectedUsers.filter(selection => selection !== target.id))
    }
  }

  const handleDeleteAccount = async () => {
    await deleteUsers(accessToken, selectedUsers)
    setShowModal(false)
    await getData()
  }

  const handleDeleteApplication = async () => {
    await deleteUserRecords(accessToken, selectedUsers)
    setShowModal(false)
    await getData()
  }

  return (
    <div className="users-container">
      <div className="nav-bar"><NavBar/></div>
      <div className="users">
        <div className="header"> Users</div>
        <div className="tool-bar">
          <div className="delete-button" onClick={() => setShowModal(true)}>Delete</div>
        </div>

        <Modal title="Delete" show={showModal} setShow={setShowModal}>
          <DeleteUser deleteAccount={handleDeleteAccount} deleteApplication={handleDeleteApplication}/>
        </Modal>

        <div className="table">
          <div className="table-header">
            <input type="checkbox" className="checkbox" checked={selectedUsers.length === users.length} onChange={handleAllCheckBoxChange}/>
          </div>
          <div className="table-header">Phone Number</div>
          <div className="table-header">Name</div>
          <div className="table-header">Email</div>
          <div className="table-header">Address</div>
          <div className="table-header">Application Status</div>
          <div className="table-header">Bank Account Linked?</div>
          {users.map(({ id, phoneNumber, name, email, address, applicationStatus, bankAccountLinked }) => (
            <Fragment key={id}>
              <div className="table-header">
                <input type="checkbox" id={id} className="checkbox" checked={!!selectedUsers.includes(id)} onChange={handleCheckBoxChange}/>
              </div>
              <div className="table-row">{formatPhoneNumber(phoneNumber)}</div>
              <div className="table-row">{name || '-'}</div>
              <div className="table-row">{email || '-'}</div>
              <div className="table-row">{address || '-'}</div>
              <div className="table-row">{applicationStatus || '-'}</div>
              <div className="table-row">{bankAccountLinked ? <CheckIcon/> : ''}</div>
            </Fragment>
          ))}
          {/*<div ref={infiniteScroll}/>*/}
        </div>
      </div>
    </div>
  )
}
