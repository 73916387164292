import axios from 'axios'

export const getUserByPhoneNumber = async (accessToken, phoneNumber) => {
  const user = await axios.get(`https://api-dev-future0.futurestash.com/admin/users?phoneNumber=1${phoneNumber}`, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
  const userId = user.data['_id']
  const userInformation = {
    id: userId,
    phoneNumber: user.data.phoneNumber
  }
  try {
    const userRecord = await axios.get(`https://api-dev-future-unit.futurestash.com/admin/userRecords?userId=${userId}`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    })
    const { name, email, address, applicationStatus, plaid } = userRecord.data
    return {
      ...userInformation,
      name: `${name.first} ${name.last}`,
      email,
      address: `${address.street} ${address.city}, ${address.state} ${address.postalCode}`,
      applicationStatus,
      bankAccountLinked: !!plaid
    }
  } catch (e) {
    return userInformation
  }
}

export const getUsers = async (accessToken) => {
  const users = await axios.get('https://api-dev-future0.futurestash.com/admin/users?skip=0&limit=200&search={}', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    }
  })
  const allUsers = await Promise.all(users.data.map(async user => {
      const userId = user['_id']
      const userInformation = {
        id: userId,
        phoneNumber: user.phoneNumber
      }
      try {
        const userRecord = await axios.get(`https://api-dev-future-unit.futurestash.com/admin/userRecords?userId=${userId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          }
        })
        const { name, email, address, applicationStatus, plaid } = userRecord.data
        return {
          ...userInformation,
          name: `${name.first} ${name.last}`,
          email,
          address: `${address.street} ${address.city}, ${address.state} ${address.postalCode}`,
          applicationStatus,
          bankAccountLinked: !!plaid
        }
      } catch (e) {
        return userInformation
      }
    }
  ))
  return allUsers.sort((a, b) => (a.name === undefined) - (b.name === undefined) || +(a.name > b.name) || -(a.name < b.name))
}

export const deleteUsers = async (accessToken, userIds) => {
  return await Promise.all(userIds.map(async userId => {
      return await axios.delete('https://api-dev-future0.futurestash.com/admin/users', {
        data: { id: userId },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      })
    }
  ))
}

export const deleteUserRecords = async (accessToken, userIds) => {
  return await Promise.all(userIds.map(async userId => {
      return await axios.delete('https://api-dev-future-unit.futurestash.com/admin/userRecords', {
        data: { userId },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        }
      })
    }
  ))
}
