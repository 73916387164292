import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Login from './pages/login/Login'
import Users from './pages/users/Users'
import { useState } from 'react'

function App () {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [accessToken, setAccessToken] = useState()

  return (
    <Router>
      <div className="container">
        <Routes>
          <Route path="/" element={<Login phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} setAccessToken={setAccessToken}/>}/>
          <Route path="/users" element={<Users accessToken={accessToken} phoneNumber={phoneNumber}/>}/>
        </Routes>
      </div>
    </Router>
  )
}

export default App
