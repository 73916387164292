import './NavBar.css'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useNavigate } from 'react-router-dom'

export default function NavBar () {
  const navigate = useNavigate()

  return (
    <div className="nav-bar-container">
      <div className="menu-container">
        <div className="header">Future</div>
        <ul className="menu">
          <li className="menu-item" onClick={() => navigate('/users')}><PersonOutlineIcon className="icon"/> Users</li>
        </ul>
      </div>
      <div className="profile"><AccountCircleIcon className="icon"/>Admin</div>
    </div>
  )
}
