import React from 'react'
import './PhoneNumber.css'

export default function PhoneNumber ({ phoneNumber, setPhoneNumber }) {
  const handlePhoneNumberChange = ({ target }) => {setPhoneNumber(target.value)}

  return (
    <div className="phone-number">
      <div className="header">Phone number</div>
      <div className="description">Enter your phone number to log in to your account</div>
      <div className="input-container">
        <div className="country-code">+1</div>
        <input type="text" autoFocus={true} value={phoneNumber} onChange={handlePhoneNumberChange}/>
      </div>
    </div>
  )
}
